import React from "react";
import FirmaDigitalSection from "../../components/Projects/FDigital/fdigital.js";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import { graphql } from "gatsby";

const firmaDigitalPage = () => (    
    <Layout>
        <SEO title="Codeicus | Fdigital" />
        <FirmaDigitalSection/>
    </Layout>
)

export default firmaDigitalPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;