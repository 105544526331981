import React from 'react';
import * as styles from '../projects.module.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Tabs from  '../Tabs/tabs';
import Process from '../Process/process';
import Square from '../Square/square';
import firma2x from '../../../images/Home/firma@2x.png';

const FirmaDigitalSection = () => {

    const {t} = useTranslation();

    return (
        <section className={styles.projectSection}>
            <div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.contentInfoLeft}>
                        <Tabs
                            active = 'fdigital'/>
                            
                        <Square
                            title = {t("projectsPage.firmaDigital.square.title")}
                            subtitle= {t("projectsPage.firmaDigital.square.subtitle")}
                            description = {t("projectsPage.firmaDigital.square.description")}
                            list = {[t("projectsPage.firmaDigital.square.list.0")
                               ]}
                            color = '#ed29a2'
                            image = {firma2x}
                        />  

                    </div>
                    <div className={styles.contentInfoRight}>                            
                        <Process 
                            number = '1'
                            title = {t("projectsPage.firmaDigital.process.1.title")}
                            description = {t("projectsPage.firmaDigital.process.1.description")}
                            list= {[]}
                        />
                        <Process 
                            number = '2'
                            title = {t("projectsPage.firmaDigital.process.2.title")}
                            description = {t("projectsPage.firmaDigital.process.2.description")}
                            list= {[t("projectsPage.firmaDigital.process.2.list.0"),
                                    t("projectsPage.firmaDigital.process.2.list.1"),
                                    t("projectsPage.firmaDigital.process.2.list.2")
                                ]}
                        />
                        <Process 
                            number = '3'
                            title = {t("projectsPage.firmaDigital.process.3.title")}
                            description = {t("projectsPage.firmaDigital.process.3.description")}
                            list= {[]}
                        />
                        <Process 
                            number = '4'
                            title = {t("projectsPage.firmaDigital.process.4.title")}
                            description = {t("projectsPage.firmaDigital.process.4.description")}
                            list= {[t("projectsPage.firmaDigital.process.4.list.0"),
                                    t("projectsPage.firmaDigital.process.4.list.1"),
                                    t("projectsPage.firmaDigital.process.4.list.2")
                                ]}
                        />
                    </div>
                </div>
            </div>
		</section>
    );
};
export default FirmaDigitalSection;